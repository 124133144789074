<template>
    <div class="body-container">
        <BannerSection title="Préstamos para emprendedores:<br class='d-none d-lg-block'/> ¡Impulsa tu negocio hoy!"
                       subtitle="Soluciones rápidas y seguras para llevar tu emprendimiento al siguiente nivel" />
        <PerksComponent></PerksComponent>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p>¿Eres emprendedor y necesitas financiamiento rápido y flexible? Descubre cómo nuestros préstamos
                        para emprendedores pueden ayudarte a crecer sin complicaciones.</p>
                    <br>
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">¿Por qué elegir nuestros préstamos?</h3>
                        

                    </span>
                    <p>Nuestros préstamos para emprendedores están diseñados para proporcionar la flexibilidad y la
                        seguridad necesarias para que puedas concentrarte en expandir y fortalecer tu empresa.</p>
                    <br>
                    <ul>
                        <li>
                            <h3 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Proceso 100% en línea:
                            </h3>
                            <span>Sabemos que el tiempo es oro, especialmente cuando diriges tu propio negocio. Por eso,
                                nuestro proceso de solicitud es completamente en línea, lo que te permite aplicar desde
                                cualquier lugar y momento. </span>
                        </li>

                        <br>
                        <li>
                            <h3 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                No necesitas dejar tu auto ni otros avales complicados
                            </h3>
                            <span>Entendemos que tu movilidad y tus activos son cruciales para tu operación diaria. Con
                                nosotros, no necesitas comprometer tus bienes personales más valiosos para obtener el
                                financiamiento que necesitas. </span>
                        </li>

                        <br>
                        <li>
                            <h3 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Flexibilidad en pagos y tasas competitivas
                            </h3>
                            <span>Nos adaptamos a las necesidades de tu negocio con planes de pago flexibles que se
                                ajustan a tu flujo de caja. Además, nuestras tasas son competitivas y justas para
                                asegurar que obtengas el mejor financiamiento posible sin cargos ocultos. </span>
                        </li>

                        <br>
                    </ul>
                    <br>
                </div>
            </div>
        </div>

        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <p>Para garantizar que nuestros préstamos se ajusten perfectamente a tus necesidades como
                            emprendedor, ofrecemos condiciones claras y favorables. </p>
                        <br>
                    </span>

                    <ul>
                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Montos desde $12,000 hasta $120., MXN:
                            </p>
                            <span> ofrecemos una gama de montos que te permitirán financiar desde pequeñas necesidades
                                hasta proyectos más ambiciosos. </span>
                        </li>

                        <br>
                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Plazos de devolución flexibles:
                            </p>
                            <span> sabemos que cada negocio tiene su ritmo. Por eso, proporcionamos plazos que se pueden
                                ajustar según tus ingresos y capacidad de pago. </span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Tasas de interés competitivas y transparentes:
                            </p>
                            <span>nos comprometemos a ofrecer tasas de interés justas y competitivas. Todas nuestras
                                tasas y cargos están completamente explicados desde el principio, sin sorpresas ni
                                letras pequeñas, para que sepas exactamente qué esperar y cómo planificar tu retorno.
                            </span>
                        </li>
                        <br>
                    </ul>

                    <h3 class="bold-text"
                        style="font-size: 20px;">Da el próximo paso hacia el éxito de tu emprendimiento
                    </h3>
                    
                    <h3 style="font-size: 20px;">Nuestros préstamos están diseñados para ofrecerte el soporte financiero que necesitas con la flexibilidad que deseas. ¿Listo para comenzar? </h3>

                   
                </div>
            </div>
        </div>

        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamosParaEmprendedores',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Préstamos para emprendedores| Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Impulsa tu negocio con un préstamo para emprendedores usando tu coche en garantía. Sin trámites complicados y aprobación rápida. ¡Haz crecer tu empresa hoy!'
                    },
                    {
                        name: 'keywords',
                        content: 'prestamos para emprendedores prestamo para negocios'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/prestamos-sin-buro', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>